import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "./withRouterHook";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { UserIcon } from "../shared";
import LogoName from "../images/logo_name.png";
import LogoImage from "../images/logo_image.png";

function Header({ history }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);
  const { route } = useAuthenticator((context) => [context.route]);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleUserIconClick = () => {
    setIsPopupOpen(true);
  };

  const handleLogout = () => {
    signOut();
    history.push("/");
  };

  const handleSignInClick = () => {
    history.push("/login");
  };

  const handleLogoClick = () => {
    history.push("/dashboard/meetings");
  };

  const handleUpgradePlan = () => {
    history.push("/upgrade-plan");
  };

  return (
    <div className="sticky top-0 z-10 px-4 sm:px-6 lg:px-8 flex items-center justify-between h-20 relative z-30 backdrop-blur-sm bg-opacity-30 flex justify-between px-12">
      <span>
        <div
          class="flex items-center justify-center flex-shrink-0 cursor-pointer"
          onClick={handleLogoClick}
        >
          <img src={LogoName} class="h-16 w-16" alt="LogoName" />
          <img src={LogoImage} class="h-8 w-8" alt="LogoImage" />
        </div>
      </span>
      <span />
      {/* USER ICON IF USER IS LOGGED IN */}
      {route === "authenticated" && (
        <div class='flex items-center justify-center'>
          <div class="mr-8 hover:opacity-50 cursor-pointer text-black/80 text-sm"><a href="https://www.helloxylo.com/onboarding" target="_blank">Getting Started</a> </div>
          <div className="relative">
                    <div
                      className="rounded-full p-4 cursor-pointer hover:opacity-60"
                      onClick={handleUserIconClick}
                    >
                      <UserIcon />
                    </div>
                    {isPopupOpen && (
                      <div
                        ref={popupRef}
                        className="absolute right-0 mt-2 py-2 w-40 bg-white border rounded-xl shadow-lg z-10 w-48 text-sm"
                      >
                        <button
                          className="block w-full px-6 py-2 text-left hover:bg-stone"
                          onClick={handleUpgradePlan}
                        >
                          Upgrade Plan
                        </button>
                        <button
                          className="block w-full px-6 py-2 text-left hover:bg-stone"
                          onClick={handleLogout}
                        >
                          Log Out
                        </button>
                      </div>
                    )}
                  </div>
        </div>
       
      )}
      {/* LOGIN BUTTON IF USER IS NOT SIGNED IN */}
      {route !== "authenticated" && (
        <button
          class="bg-purple px-4 py-2 text-white hover:opacity-60 cursor-pointer"
          onClick={handleSignInClick}
        >
          Sign in
        </button>
      )}
    </div>
  );
}

export default withRouter(Header);
