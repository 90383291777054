import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { withRouter } from "./withRouterHook.js";
import scheduled from "../images/scheduled.png";
import analysedCall from "../images/analysedCall.png";
import LandingPageHeader from "./LandingPageHeader.js";


class Onboarding extends React.Component {

  handleBackClick = () => {
    const {history}  = this.props;
    history.push("/dashboard/meetings");
  }

  render() {

    return (
      <div>
       <LandingPageHeader />
       <div class="px-20 pt-12">   
        <div class="flex justify-between">
          <ArrowLeftIcon class="h-8 w-8 mb-8 cursor-pointer" onClick={this.handleBackClick} /><div class="font-manrope text-3xl">How to set up a meeting with Xylo</div> <div />
          </div>
          <div class='px-12'>
              <div class="pb-8">{`1) Sign up `}<a href="https://www.helloxylo.com/login" target="_blank" class="underline text-indigo-600">here</a><span>{` and create a free account. The activation code may get sent to your Junk folder so please check there`}</span></div>
              <div class="pb-8">{`2) Go to your meetings dashboard and set up a new meeting as shown below`}</div>  
          </div>
          
        <div class="px-12 pb-12">
        <div style={{
          position: 'relative',
          paddingBottom: 'calc(58.46527777777778% + 42px)',
          height: 0
          }}><iframe src="https://app.supademo.com/embed/clxx99i2l156k12o2hf826le0" title="Supademo Demo" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe></div></div>
        <div class="px-12 pb-36">
          <div class="pb-8">{`3) Once you have scheduled your meeting, it will appear with a `}<span class="text-amber-600">"Scheduled "</span><span>status in the dashboard</span></div>
          <div class=" pb-8">{`4) When you are in the meeting, you must admit Xylo to join from the waiting room. It will be called "Meeting Notetaker (Guest)" `}</div>
          <div class=" pb-8">{`5) Once Xylo is successfully on the call, it will send you a message in the chat saying "Xylo has joined the call and is now recording"`}</div>
          <div class="pb-8">{`6) When the meeting & analysis is complete, the meeting will appear with `}<span class="text-emerald-600">"View Details"</span><span>status in the dashboard. Click on it to access the video, transcripts and analysis.</span></div>
          <div class = "mt-16 pb-4  text-center text-black/60">Your scheduled and analysed meetings will appear in the dashboard</div>
          <img class="h-[300px] mx-auto border border-indigo-500 rounded-xl" src={scheduled} />
          <div class = "mt-32 pb-4 text-center text-black/60">Once you click on View Details, you can generate an analysis</div>
          <img class="h-[500px] mx-auto border border-indigo-500 rounded-xl pb-12" src={analysedCall} />
          <div class="py-8">{`To delete you account and all data associated with it (including your email & all meeting data) please email amrit@helloxylo.com and provide your account email with a request to delete your account.`}</div>
          
        </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Onboarding);
