import React, { Component } from "react";
import { withRouter } from "./withRouterHook";
import LandingPageHeader from "./LandingPageHeader";
import { ArrowLeftIcon} from "@heroicons/react/24/outline";
import teamsLink from "../images/teams_meeting_link.png";
import google_new from "../images/google_meet.png";
import google_existing from "../images/google_in_meeting.png";

class Teams extends Component {

  componentDidMount(){
    window.scrollTo(0, 0);
  }
  
  handleBackClick = () => {
    const {history}  = this.props;
    history.push("/dashboard/meetings");
  }

  render() {
    return(
      <div>
        <LandingPageHeader />
       <div class="pt-12 mb-16 px-4 xl:px-24">
       {/* <div><ArrowLeftIcon class="h-8 w-8 mb-8 cursor-pointer" onClick={this.handleBackClick} /></div> */}
        <div class="text-center text-xl mb-8 text-indigo-600">How to get a Microsoft Teams meeting link</div>
        <img class="max-w-xl mx-auto" src={teamsLink} />
        <div class="px-12 space-y-4 pt-8 text-sm">
            <div class="">Once you have copied the link, paste it into Xylo.</div>
            <div class="">The format of the link will look like this: </div>
            <div class="text-black/60">https://teams.microsoft.com/l/meetup-join/19%3ameeting_ODRmZGZjNWItZWYyZi00MTdhLWFlOTYtODU4Y2U2NmY4ZjQ4%40thread.v2/0?context=%7b%22Tid%22%3a%226e519a92-6691-4ee2-ac7a-dd475a24d906%22%2c%22Oid%22%3a%2225275e54-8777-48e6-a116-d0d03ec1dbe5%22%7d</div>
        </div>
        </div>
        {/* google */}
        <div class="text-center text-xl my-8 text-indigo-600">Google Meet link - Create a Meeting for Later</div>
        <img class="max-w-md mx-auto" src={google_new} />
        <div class="text-center text-xl my-8 text-indigo-600">Google Meet link - Start an Instant Meeting</div>
        <img class="max-w-sm mx-auto" src={google_existing} />
        <div class="px-12 space-y-4 pt-8 text-sm pb-20">
            <div class="">There are 2 ways to get a link when you start an instant meeting</div>
            <div class="">{`1) Copy the link from the browser bar (pink arrow)`}</div>
            <div class="">{`2) Click on the info button and copy the joining info link (green arrow)`}</div>
            <div class="">The format of the link will be <span class="text-black/70">https://meet.google.com/tie-bicc-rzj</span></div>
        </div>
      </div>
    )
  }
}

export default withRouter(Teams);
