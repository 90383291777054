import React, { Component } from "react";
import LandingPageHeader from "./LandingPageHeader";
import { InlineWidget } from "react-calendly";
import { withRouter } from "./withRouterHook";

class ContactPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }

  render() {
    return (
      <div>
        <LandingPageHeader showPricing={false} showLogin={false}/>
        <div class="pb-12">
          <div>
            <div class="max-w-3xl mx-auto sm:py-18">
              <div class="text-4xl sm:text-5xl text-center font-bold py-12 font-manrope text-indigo-500">
                Take a tour of Xylo
              </div>
              <div class="text-sm sm:text-lg text-center px-8">
                Let's talk about how Xylo can automate your note-taking and save you hours each week
              </div>
              <div class="text-sm text-black/60 text-center px-8 pt-4">
               For any questions, please email amrit@helloxylo.com
              </div>
              <div>   
              <div id="schedule_form" class="px-4 mb-24 flex justify-center items-center mt-12">
                  <div 
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/amrit-8zl3/30min?hide_landing_page_details=1&hide_gdpr_banner=1"
                    style={{ width: '350px', height: '650px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ContactPage);
