import React, { Component } from "react";
import {
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { MENU_ITEMS, isEmpty } from "../shared";
import { withRouter } from "./withRouterHook";
import Header from "./Header";
import { getFollowupEmail, getIntelligence, getMeetingData, saveEditedAnalysis, saveEditedTranscript } from "../service/CreateMeeting";
import dayjs from 'dayjs';
import ReactVideoPlayer from "./ReactVideoPlayer";
import AnalysisComponent from "./AnalysisComponent/AnalysisComponent";
import { extractTextFromHTML, generateHTML, getParsedEmail, getStringTranscript, processIntelligence } from "../utils";
import TemplateDropdown from "./TemplateDropdown";
import LargeLoadingSpinner from "./LargeLoadingSpinner";
import FollowupEmail from "./FollowupEmail";

const TABS = {
  MEETING_NOTES: "Meeting Notes",
  EMAIL: "Email"
};

class MeetingDetails extends Component {
  state = {
    surveyResponse: null,
    isLoading: false,
    uploadFetchingError: false,
    surveyResponseId: null,
    startTime : 0,
    autoPlay: false,
    timestamps: [],
    showError: false,
    meetingName: "",
    showResults: false,
    existingIntelligence: false,
    intelligence: null,
    showAnalysisError: false,
    showAnalysisDivs: false,
    isLoadingAnalysis: false,
    cdmComplete: false,
    isLoadingSaveEdit: false,
    saveStatus: null,
    saveAnalysisStatus: null,
    selectedTab: TABS.MEETING_NOTES,
    followupEmail: null,
    rawIntelligence: null,
    isLoadingEmail: false,
    isEmailError: false
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    //Get survey details from DB
    const { match } = this.props;
    const { meetingId } = match.params;
    this.setState({ isLoading: true });
    const meetingData = await getMeetingData({
      meetingId
    });
    if (isEmpty(meetingData)) {
      this.setState({ uploadFetchingError: true });
    } else {
      const {
        transcript,
        videoUrl,
        meetingTime,
        meetingName,
        botId,
        intelligence,
        editedHtmlTranscript,
        editedHtmlAnalysis,
        followupEmail,
       } = meetingData;
       const existingIntelligence = !isEmpty(intelligence) ? true : false;
       //If this is the first time getting the original, unedited transcript then we need to convert it to a stringHTML so it renders in the editor.
       //Otherwise if we are fetching an edited transcript, we are already getting it in a HTML string so no processing needs to be done
       const stringTranscript = !isEmpty(editedHtmlTranscript) ? editedHtmlTranscript : getStringTranscript({transcript});

      this.setState({
        isLoading: false,
        transcript: stringTranscript,
        meetingTime: dayjs(meetingTime).format('DD MMM YYYY, HH:mm'),
        videoUrl,
        meetingName,
        botId,
        htmlIntelligence: editedHtmlAnalysis ? editedHtmlAnalysis : generateHTML({intelligence}),
        existingIntelligence,
        cdmComplete: true,
        followupEmail: getParsedEmail({email: followupEmail}),
        rawIntelligence: intelligence
      });
    }
  }

  handleGenerateEmail = async () => {
    console.log("Generating")
    this.setState({isLoadingEmail: true});
    //Get email
    const { match } = this.props;
    const { meetingId } = match.params;
    const email = await getFollowupEmail({meetingId});
    if(!email){
      this.setState({isEmailError: true, isLoadingEmail: false});
    }
    else {
      const followupEmail = getParsedEmail({email});
      console.log("followup email", followupEmail);
      this.setState({isLoadingEmail: false, followupEmail: followupEmail});
    }
  }

  handleSaveEdits = async ({editedTranscript}) => {
    this.setState({isLoadingSaveEdit: true});
    const { match } = this.props;
    const { meetingId } = match.params;
    //Convert edited HTML transcript into a string and save to DB
    const editedTextTranscript = extractTextFromHTML({htmlString: editedTranscript});
    const response = await saveEditedTranscript({editedTextTranscript, meetingId, editedHtmlTranscript: editedTranscript});
    if (response === "Success") {
      this.setState({isLoadingSaveEdit: false, saveStatus: "success"});
    }
    else {
      this.setState({isLoadingSaveEdit: false, saveStatus: "error"});
    }   
  }

  handleSaveEditsAnalysis = async ({editedTranscript}) => {
    this.setState({isLoadingSaveEditAnalysis: true});
    const { match } = this.props;
    const { meetingId } = match.params;
    //Save edited HTML to DB (no need to convert to string as we do for the transcript as we only need HTML to render - not sending to Claude for analysis)
    const response = await saveEditedAnalysis({ meetingId, editedHtmlAnalysis: editedTranscript});
    if (response === "Success") {
      this.setState({isLoadingSaveEditAnalysis: false, saveAnalysisStatus: "success"});
    }
    else {
      this.setState({isLoadingSaveEditAnalysis: false, saveAnalysisStatus: "error"});
    }   
  }

  handleTemplateSelection = async ({selectedOptionValue}) => {
    const {botId} = this.state;
    const { match } = this.props;
    const { meetingId } = match.params;
    this.setState({isLoadingAnalysis: true});
    const intelligence = await getIntelligence({ meetingId, botId, template: selectedOptionValue});
    const htmlIntelligence = generateHTML({intelligence});
    const processedIntelligence =  processIntelligence({intelligence});
    if(isEmpty(processedIntelligence)){
      this.setState({rawIntelligence: intelligence, showAnalysisError: true, isLoadingAnalysis: false})
    }
    else {
      this.setState({rawIntelligence: intelligence, showResults: true, intelligence: processedIntelligence, isLoadingAnalysis: false, htmlIntelligence: htmlIntelligence})
    }
  }


  handleMenuItemClick = (menuItem) => {
    const { history } = this.props;
    const { match } = this.props;
    const { surveyId } = match.params;
    if (menuItem === MENU_ITEMS.INSIGHTS) {
      history.push(`/dashboard/recordings/${surveyId}/insights`);
    } else if (menuItem === MENU_ITEMS.VOICES) {
      history.push(`/dashboard/recordings/${surveyId}/voices`);
    } else if (menuItem === MENU_ITEMS.SURVEY) {
      history.push(`/dashboard/recordings/${surveyId}/view-survey`);
    }
  };

  handleBackButtonClick = () => {
    this.props.history.push("/dashboard/meetings");
  };

  onSentimentClick = ({startTime}) => {
    this.setState({startTime: startTime, autoPlay: true})
  }

  onKeywordClick = ({timestamps}) => {
    this.setState({timestamps})
  }

  handleTabClick = ({tab}) => {
    this.setState({selectedTab: tab});
  }

  render() {
    const {
      transcript,
      videoUrl,
      meetingTime,
      meetingName,
      intelligence,
      showError,
      showResults,
      existingIntelligence,
      isLoadingAnalysis,
      showAnalysisError,
      cdmComplete,
      htmlIntelligence,
      saveStatus, 
      isLoadingSaveEdit,
      isLoadingSaveEditAnalysis,
      isLoading,
      saveAnalysisStatus,
      selectedTab,
      followupEmail,
      isLoadingEmail,
      isEmailError
     } = this.state;
     const showAnalysisDivs = existingIntelligence ? existingIntelligence : showResults ? showResults : false;
     const showSummary = showAnalysisDivs && !isEmpty(htmlIntelligence);

   
    return (
      <div>
        <Header />
        <div class="px-4 xl:px-8 max-w-screen-3xl xl:mx-auto h-screen">
          <div class="pb-6 sticky top-0 z-10 flex items-center">
            <ArrowLeftIcon
              className="inline h-6 w-6 text-gray-600 mr-4 cursor-pointer hover:scale-110 ml-4 sm:ml-0"
              onClick={this.handleBackButtonClick}
            />
           {!isEmpty(meetingTime) && <div class="px-4 sm:px-8  text-lg font-semibold">{`${meetingName ? `${meetingName} -` : "Meeting on"} ${meetingTime}`}</div>}
        
          </div>
          {isLoading && <div class="flex pt-24 items-center justify-center"><LargeLoadingSpinner /></div>}
          {showError && <div class="flex items-center justify-center pt-12">Error loading your meeting. Please contact support at amrit@helloxylo.com for assistance.</div>}
          {!showError && !isLoading && <div class="flex flex-col sm:flex-row">
            <div class="sm:min-w-1/12">
            </div>
            <div class="sm:w-full sm:pb-4">             
              <div class="flex flex-col sm:flex-row">
                  <div class="sm:w-full">
                  <div class="w-full flex mb-8">
                    <div class="w-1/2 mx-6">
                      <ReactVideoPlayer videoUrl={videoUrl} transcript={transcript} handleSaveEdits={this.handleSaveEdits} isLoadingSaveEdit={isLoadingSaveEdit} saveStatus={saveStatus} />
                    </div>
                    {<div class="w-1/2 rounded-md px-6 pb-6 space-y-6 text-sm">
                    <div class = "space-x-4">
                      <span onClick={() => this.handleTabClick({tab: TABS.MEETING_NOTES})} class={`cursor-pointer pb-2 ${selectedTab === TABS.MEETING_NOTES ? "text-indigo-600 border-b-2 border-indigo-600" : "text-black/60 hover:text-black "}`}>Meeting Notes</span>
                      <span  onClick={() => this.handleTabClick({tab: TABS.EMAIL})} class={`cursor-pointer  pb-2 ${selectedTab === TABS.EMAIL ? "text-indigo-600 border-b-2 border-indigo-600" : "text-black/60 hover:text-black "}`}>Follow-Up Email</span>
                    </div>
                    {/* {cdmComplete && !showSummary &&  <div class="uppercase text-amber-700 font-bold text-base">Meeting Notes</div>} */}
                    {selectedTab === TABS.MEETING_NOTES  && cdmComplete && !showAnalysisError && (isEmpty(htmlIntelligence)) ? <TemplateDropdown handleTemplateSelection={this.handleTemplateSelection} isLoadingAnalysis={isLoadingAnalysis} /> : null}
                    {showAnalysisError && <div class="py-8 flex items-center justify-center">Still generating analysis. Please refresh this page in 2 minutes. If experiencing issues, please contact amrit@helloxylo.com for help</div>}
                    {selectedTab === TABS.MEETING_NOTES  && showSummary && <div> <AnalysisComponent text={htmlIntelligence} handleSaveEdits={this.handleSaveEditsAnalysis} isLoadingSaveEdit={isLoadingSaveEditAnalysis} saveAnalysisStatus={saveAnalysisStatus}/></div>}
                    {selectedTab === TABS.EMAIL && <div><FollowupEmail followupEmail={followupEmail} handleGenerateEmail={this.handleGenerateEmail} isLoadingEmail={isLoadingEmail} isEmailError={isEmailError}/></div>}
                    </div>}
                  </div>
                  </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default withRouter(MeetingDetails);
