import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Amplify } from "aws-amplify";
import {
  Authenticator,
  View,
  ThemeProvider,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { SignInHeader } from "./Authenticator/SignInHeader";
import { CreateAccountHeader } from "./Authenticator/CreateAccountHeader";
import { awsExports } from "../aws-exports";
import "@aws-amplify/ui-react/styles.css";
import LandingPageHeader from "./LandingPageHeader";
import "./Authenticator/styles.css";
import Jon from "../images/jon.jpg";
import LogoName from "../images/logo_name.png";
import LogoImage from "../images/logo_image.png";

Amplify.configure(awsExports);

const components = {
  SignIn: {
    Header: SignInHeader,
  },
  SignUp: { Header: CreateAccountHeader },
};

function LoginPage() {
  window.scrollTo(0, 0);
  const { route } = useAuthenticator((context) => [context.route]);
  // const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (route === "authenticated") {
      navigate("/dashboard/meetings");
    }
  }, [route, navigate]);

  const formFields = {
    signIn: {
      username: {
        label: "Email",
        placeholder: "",
        type: "email",
        order: 1,
      },
      password: {
        label: "Password",
        placeholder: "",
        order: 2,
      },
    },
    signUp: {
      username: {
        label: "Email",
        placeholder: "",
        type: "email",
        order: 1,
      },
      password: {
        label: "Password",
        placeholder: "",
        order: 2,
      },
      confirm_password: {
        label: "Confirm Password",
        placeholder: "",
        order: 3,
      },
    },
  };

  const theme = {
    name: "Auth Theme",
    tokens: {
      colors: {
        font: {
          interactive: {
            value: "black",
          },
        },
        brand: {
          primary: {
            80: "black",
            90: "#022963",
            100: "#022963",
          },
        },
      },
    },
  };

  return (
    <div class="h-screen">
      <LandingPageHeader showPricing={false} showLogin={false} />
      <div class="flex flex-col lg:flex-row h-full  lg:bg-white">
            <div class="lg:hidden py-12 ">
                  <div class="font-manrope text-center text-3xl flex items-center justify-center">Welcome to 
                      <span>
                      <span
                        class="flex items-center justify-center flex-shrink-0 px-2 pt-1"
                      
                      >
                        <img src={LogoName} class="h-16 w-16" alt="LogoName" />
                        <img src={LogoImage} class="h-8 w-8" alt="LogoImage" />
                      </span>
                    </span>
                  </div>
                  {/* text */}
                  <div class="text-center pt-4 lg:pt-12 px-8 lg:px-0">Sign up to save hours each week by automating note-taking in your client meetings.</div>
             </div>
        <div class="lg:w-1/2"> 
                <ThemeProvider theme={theme}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "80vh",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    fontFamily: "Plus Jakarta Sans",
                  }}
                >
                  <Authenticator
                    formFields={formFields}
                    components={components}
                    usernameAlias="email"
                    initialState="signUp"
                  ></Authenticator>
                </View>
              </ThemeProvider>
        </div>
        <div class="lg:w-1/2 h-full flex items-start justify-center lg:py-0"> 
        <div class="hidden lg:flex justify-center items-center pt-12 lg:pt-0">
          <div class="hidden lg:block">
              <div class="font-manrope text-center text-3xl flex items-center justify-center">Welcome to 
                  <span>
                  <span
                    class="flex items-center justify-center flex-shrink-0 px-2 pt-1"
                  
                  >
                    <img src={LogoName} class="h-16 w-16" alt="LogoName" />
                    <img src={LogoImage} class="h-8 w-8" alt="LogoImage" />
                  </span>
                </span>
              </div>
              {/* text */}
              <div class="text-center pt-4 lg:pt-8 px-8">Sign up to save hours each week by automating note-taking in your client meetings.</div>
          </div>
          
        </div>
        
         
         </div>
      </div>
     
    </div>
  );
}

export default LoginPage;
