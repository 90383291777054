import React, { Component } from "react";
import {  isEmpty } from "../shared";
import { withRouter } from "./withRouterHook";
import ReactPlayer from 'react-player';
import AnalysisComponent from "./AnalysisComponent/AnalysisComponent";
import { MOCK_VIDEO } from "../utils";

class ReactVideoPlayer extends Component {
  state = {
  };

  selectText = () => {
    const div = document.getElementById('myDiv');

    div.addEventListener('keydown', (e) => {
      if(e.ctrlKey && e.key === 'c') {
        e.preventDefault();
        
        // Select text in div
        const selection = window.getSelection();
        selection.removeAllRanges();
        const range = document.createRange();
        range.selectNodeContents(div);
        selection.addRange(range);
      }
    });
  }

  
  render() {
    const { transcript, handleSaveEdits, isLoadingSaveEdit, saveStatus} = this.props;
    const { videoUrl } = this.props;
    // const videoUrl = MOCK_VIDEO;
      return <div class="flex">
          <div>
            {!isEmpty(videoUrl) && <ReactPlayer class="mx-auto rounded-lg overflow-hidden" url={videoUrl} controls={true} width="300px" height={"168px"} style={{borderRadius: "0.875rem"}}/>}
            <div>
              <div>
                <AnalysisComponent text={transcript} isTranscriptComponent={true} handleSaveEdits={handleSaveEdits} isLoadingSaveEdit={isLoadingSaveEdit} saveStatus={saveStatus} isEmptyVideo={isEmpty(videoUrl)} />
              </div>           
            </div>
          </div>
        <div>
      
        </div>
    </div>
  }
}

export default withRouter(ReactVideoPlayer);
