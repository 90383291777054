import React from "react";
import { useState, useEffect } from 'react';
import { 
  EditorProvider,
  Editor,
  Toolbar,
  BtnBold,
  BtnItalic,
  BtnUndo,
  BtnRedo,
  BtnUnderline,
} from 'react-simple-wysiwyg';
import ColourLoadingSpinner from "../ColourLoadingSpinner";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

function AnalysisComponent({text, isTranscriptComponent = false, isEmail = false, handleSaveEdits, isLoadingSaveEdit, saveStatus, saveAnalysisStatus, isEmptyVideo=false}) {
  const [value, setValue] = useState(text);


  useEffect(() => {
    setValue(text);
  }, [text]);
 
  function onChange(e) {
    setValue(e.target.value);
  }
  return (
    <div id={isTranscriptComponent ? "transcript-editor" : "analysis-editor"}>
             {<div class="flex justify-between items-center py-2 pr-6">
                <div class="uppercase text-amber-700 font-bold text-base">{isTranscriptComponent ? "Transcript" : ""}</div>
                 <span></span>
                {isLoadingSaveEdit ? <ColourLoadingSpinner /> : 
                  !isEmail ? <div class="flex items-center justify-center">
                    <button class="flex-shrink-0 px-4 py-2 text-white bg-indigo-700 flex items-center justify-center rounded-full hover:opacity-45 text-sm" onClick={() => handleSaveEdits({editedTranscript: value})}>Save Edits</button>
                    {saveStatus === "success" && <CheckCircleIcon class="ml-2 h-8 w-8 text-emerald-600" />}
                    {saveStatus === "error" && <ExclamationCircleIcon class="ml-2 h-8 w-8 text-red-700" />}
                    {saveAnalysisStatus === "success" && <CheckCircleIcon class="ml-2 h-8 w-8 text-emerald-600" />}
                    {saveAnalysisStatus === "error" && <ExclamationCircleIcon class="ml-2 h-8 w-8 text-red-700" />}
                  </div> : null}
              </div>}
              <div class={`${isTranscriptComponent ? `text-xs rounded-md shadow-sm pb-4 break-words overflow-y-scroll whitespace-pre-line ${isEmptyVideo ? 'max-h-[500px]' : 'max-h-[600px]'}` : "text-xs rounded-md shadow-sm pb-4 break-words overflow-y-scroll whitespace-pre-line max-h-[calc(100vh-200px)]"}`}>
              <EditorProvider>
                <Editor value={value} onChange={onChange} containerProps={{ style: { border: 'none', minHeight: "200px" } }}  >
                  <Toolbar>
                    <BtnUndo/>
                    <BtnRedo />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                  </Toolbar>
                  </Editor>
                  </EditorProvider>
                </div>
      </div>


  );
}

export default AnalysisComponent;
