import React, { Component } from "react";
import LandingPageHeader from "./LandingPageHeader";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ContactModal from "./Modals/ContactModal";
import { PAYMENT_LINK, PRICING_PAGE } from "../shared";


class PricingCards extends Component {
  handleStarterTierClick = () => {
    // Test Starter Tier payment link below
    // window.open("https://buy.stripe.com/test_28odTKfy92HBayI6op", "_blank");
    window.open(PAYMENT_LINK.STARTER);
  };

  handleProTierClick = () => {
    // Once the stripe link is clicked and payment successfully made, it will update userInfo in DB for plan type (isProTier: true) and then redirect to /dashboard
    // Test Pro Tier payment link below
    // window.open("https://buy.stripe.com/test_fZe3f699Lbe7cGQcMO", "_blank");
    window.open(PAYMENT_LINK.PRO, "_blank");
  }

  render() {
    const {type, handleModalClose, handleContactSales, showContactModal, handlePricingPageTierClick, handleCloseButtonClick, handleFreePlanButtonClick} = this.props;
    return (
      <div class="sm:h-screen">
        <LandingPageHeader showPricing={false} showLogin={false} />
        {type === PRICING_PAGE.UPGRADE && 
        <div class="flex justify-end px-8 xl:px-28"> 
          <XMarkIcon
                className="inline h-10 w-10 mr-4 cursor-pointer hover:scale-105 text-black/90"
                onClick={handleCloseButtonClick}
              />
        </div>}
        <div class="pb-8 sm:pb-52">
          {showContactModal && (
            <ContactModal handleModalCloseFn={handleModalClose} />
          )}
          <div class={`${showContactModal ? "blur-xl" : ""} px-8 lg:px-28 xl:px-48`}>
            <div class="max-w-3xl mx-auto py-6 sm:py-12" >
              {type === PRICING_PAGE.CONFIRM && <div class="text-4xl text-center font-bold py-6 font-manrope">
                Confirm the plan you would like to choose
              </div>}
              {type === PRICING_PAGE.UPGRADE && (
              <div class="text-4xl text-center font-bold py-6 font-manrope">
                Choose the best plan to grow your{" "}
                <span class="scaling-animation"> business</span>
              </div>)
              }
              {type === PRICING_PAGE.PRICING &&
                <div>
                <div class="text-black text-6xl text-center font-bold py-6 font-manrope">
                Pricing
              </div>
              <div class="text-black/80 text-lg text-center">
                Ready to save hours by automating notetaking in meetings? 
              </div>
             
                </div>
              }
            </div>
            <div class="flex items-center justify-center">
              {/* <div class="pb-10">
                <div class="flex justify-center gap-4">
                  <div
                    onClick={() =>
                      handlePlanClick({
                        selectedPlan: SELECTED_PLAN.MONTHLY,
                      })
                    }
                    class={`rounded-full px-4 py-1 cursor-pointer hover:opacity-70 ${
                      selectedPlan === SELECTED_PLAN.MONTHLY
                        ? "bg-light-purple border border-dark-purple text-indigo-900 font-semibold"
                        : "bg-white text-black/50 border"
                    }`}
                  >
                    Monthly
                  </div>
                  <div
                    onClick={() =>
                      handlePlanClick({
                        selectedPlan: SELECTED_PLAN.YEARLY,
                      })
                    }
                    class={`rounded-full px-4 py-1 cursor-pointer hover:opacity-70 ${
                      selectedPlan === SELECTED_PLAN.YEARLY
                        ? "bg-light-purple border border-dark-purple text-indigo-900 font-semibold"
                        : "bg-white text-black/50 border"
                    }`}
                  >
                    Yearly
                  </div>
                </div>
              </div> */}
              <div class={`grid gap-x-16 gap-y-16 sm:grid-cols-1 md:grid-cols-2 ${type === PRICING_PAGE.UPGRADE ? `lg:grid-cols-2`: `lg:grid-cols-3`} grid-rows-auto auto`}>
                {/* Pricing Card 1 */}
                {type !== PRICING_PAGE.UPGRADE && <div class="rounded-md px-6 py-8 border bg-white shadow-sm max-w-sm">
                  <div class="flex flex-col gap-8">
                    <div class="uppercase text-center text-black font-bold">
                      Free 7 day trial
                    </div>
                    <div class="hidden sm:block">
                        <div class="">
                          <div class="text-4xl font-bold text-center">Free</div>
                        </div>
                        <div class="my-4 text-sm text-center text-white">.</div>
                        <div class="text-sm text-black/70 text-center text-white">
                          .
                        </div>
                        <div class="text-sm text-black/70 text-center text-white">
                          .
                        </div>
                        <div class="text-sm text-black/70 text-center text-white">
                          .
                        </div>
                        <div class="text-sm text-black/70 text-center text-white">
                          .
                        </div>
                      </div>
                    <button
                      class="uppercase text-black rounded-md hover:opacity-70 border border-indigo-900 font-bold py-2"
                      onClick={type === PRICING_PAGE.PRICING ? handlePricingPageTierClick : type === PRICING_PAGE.CONFIRM ? handleFreePlanButtonClick : this.handleStarterTierClick}
                    >
                      Start Free Trial
                    </button>
                    <div class="flex flex-col gap-2 text-sm">
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>3 meetings</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Meeting Summaries</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Key Client Information</span>
                      </div>
                    </div>
                  </div>
                </div>}
                {/* Pricing Card 2 */}
                <div class="rounded-md px-6 py-8 border border-indigo-600 bg-white shadow-xl max-w-sm">
                  <div class="flex flex-col gap-8">
                    <div class="uppercase text-center text-black font-bold">Starter</div>    
                      <div>
                        <div class="">
                          <div class="text-4xl font-bold text-center">Contact Us</div>
                        </div>
                        <div class="my-4 text-sm text-center"> price monthly/user</div>
                        <div class="text-sm text-black/70 pt-4">
                          Best for solo professionals looking to save a few hours by automating note taking in meetings.
                        </div>
                      </div>
                    <button
                      class="uppercase rounded-md hover:opacity-70 border  font-bold py-2 bg-indigo-500 text-white flex justify-center"
                      onClick={type === PRICING_PAGE.PRICING ? handlePricingPageTierClick : this.handleStarterTierClick}
                    >
                      Get Starter
                    </button>
                    <div class="flex flex-col gap-2 text-sm">
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>10 meeting hours</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>Automatic follow-up emails</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Meeting summaries</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Key Client Information</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>Priority Support</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pricing Card 3 */}
                <div class="rounded-md px-6 py-8 border bg-white shadow-sm max-w-sm">
                  <div class="flex flex-col gap-8">
                    <div class="uppercase text-center text-black font-bold">Pro</div>
                    <div>
                        <div class="">
                          <div class="text-4xl font-bold text-center">Contact Us</div>
                        </div>
                        <div class="my-4 text-sm text-center"> price monthly/user</div>
                        <div class="text-sm text-black/70 pt-4">
                          Best for teams who have several meetings a week, looking to save several hours by automating note taking in meetings.
                        </div>
                      </div>
                    <button
                      class="uppercase text-black rounded-md hover:opacity-70 border border-indigo-900 font-bold py-2"
                      onClick={type === PRICING_PAGE.PRICING ? handlePricingPageTierClick : this.handleProTierClick}
                    >
                      Get Pro
                    </button>
                    <div class="flex flex-col gap-2 text-sm">
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>40 meeting hours</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Meeting summaries</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Key Client Information</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>VIP support</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pricing Card 3 */}
                {/* <div class="rounded-md px-6 py-8 border bg-white shadow-sm max-w-sm">
                  <div class="flex flex-col gap-8">
                  <div class="uppercase text-center text-black font-bold">Enterprise</div>    
                      <div class ="hidden sm:block">
                        <div class="">
                          <div class="text-4xl font-bold text-center">Custom</div>
                        </div>
                        <div class="my-4 text-sm text-white"> .</div>
                        <div class="text-sm text-white pt-4">
                          ----------------------------------------------------------------------------------------------------------------------
                        </div>
                      </div>
                    <button
                      class="uppercase text-black rounded-md hover:opacity-70 border border-indigo-900 font-bold py-2"
                      onClick={handleContactSales}
                    >
                      Contact Us
                    </button>
                    <div class="flex flex-col gap-2 text-sm">
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>Unlimited meetings</span>
                      </div>      
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>Same day support</span>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PricingCards;
