import { Component } from "react";
import { TrashIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import DeleteSurveyModal from "./Modals/DeleteSurveyModal";
import dayjs from 'dayjs';
import { deleteBot } from "../service/CreateMeeting";
import { getStatusColour } from "../utils";
import { isEmpty } from "../shared";

class MeetingDiv extends Component {

  state = {
    showDeleteModal: false,
    meetingId: this.props.meeting._id,
    isDeleteLoading: false
  }


  handleDeleteMeeting = () => {
    const {handleDeleteBlur} = this.props;
    this.setState({ showDeleteModal: true });
    handleDeleteBlur({showDeleteModal: true});
  };

  handleDeleteModalCloseFn = () => {
    const {handleDeleteBlur} = this.props;
    this.setState({ showDeleteModal: false });
    handleDeleteBlur({showDeleteModal: false});
  };

  handleDeleteModalConfirmation = async () => {
    this.setState({isDeleteLoading: true});
    const { botId,  _id: meetingId, } = this.props.meeting;
    await deleteBot({ botId, meetingId });
    this.setState({showDeleteModal: false, isDeleteLoading: false});
    window.location.reload();
  };


render() {
    const { meeting, handleMeetingClickFn, blurDiv } = this.props
    const {showDeleteModal, isDeleteLoading} = this.state;
    const {
        _id: meetingId,
        meetingTime,
        status,
        botId,
        meetingName
      } = meeting;

      return( 
        <div>
          {showDeleteModal && (<DeleteSurveyModal
          handleDeleteModalCloseFn={this.handleDeleteModalCloseFn}
          handleDeleteModalConfirmation={
          this.handleDeleteModalConfirmation
          }
          isDeleteLoading={isDeleteLoading}
        />)}
      <div class={`flex items-center`}>
       <div
        class={`p-1 sm:p-4 flex flex-row justify-between gap-3 md:gap-8 mb-6  w-full rounded-md bg-white/90 border backdrop-blur-2xl shadow-md ${status !== "Processed" ? "cursor-default" : "cursor-pointer hover:border-black/10 hover:-translate-y-0.5 shadow hover:shadow-md transition shadow-black/10 "} ${blurDiv ? "blur-sm": ""}`}
        key={meetingId}
        disabled={true}
        onClick={() => {
          handleMeetingClickFn({meetingId, status});
        }}
      >
        <div class="px-2 py-1 w-full grid grid-cols-4 gap-4 p-4">
          {/* CHILD 1 */}
          <div class="text-sm tracking-wide font-bold flex items-center justify-start">
            {`${!isEmpty(meetingName) ? `${meetingName}` : "Meeting"}`}
          </div>
          <div></div>
          {/* CHILD 2 */}
          <div class="flex items-center justify-center text-sm">
              <div class="flex-shrink-0 pr-6">{`${dayjs(meetingTime).format('DD MMM YYYY')}`}</div>
              <div>{`${dayjs(meetingTime).format('HH:mm')}`}</div>
          </div>
          {/* CHILD 3 */}
          <div class="flex items-center justify-center">
            {<div class="text-sm flex items-center justify-center text-black/60 hover:text-black md:flex-shrink-0">
              {status !== "Processed" ? <span class={`${getStatusColour({status})} px-8 text-sm`}>{status}</span> : <span class={`${getStatusColour({status})} px-8 text-sm`}>View Details</span>}
              {status === "Processed" && <ArrowRightIcon class="h-4 w-4 inline text-neutral-500"/>}
            </div>}
          </div>
        </div>      
      </div>
      {/* Delete */}
     { <div class="px-6"><TrashIcon class="h-5 w-5 opacity-50 hover:opacity-100 cursor-pointer" onClick={() => this.handleDeleteMeeting({botId})} /></div>}
     {/* { status === "Processed" && <div class="px-6"><div class="h-5 w-5 " /></div>} */}
      </div>
      </div> 
      )
    }
}

export default MeetingDiv;