import React, { Component } from "react";
import LogoName from "../../images/logo_name.png";
import LogoImage from "../../images/logo_image.png";
import LandingPageHeader from "../LandingPageHeader";
import { withRouter } from "../withRouterHook";
import "../../styles.css";
import IntroCall from "./introcall.png";
import { CheckIcon, ArrowTrendingDownIcon, UserPlusIcon, ShieldCheckIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import Google from "./googlemeet.jpg";
import Teams from "./teams.jpg";
import Zoom from "./zoom.png";
import soc2 from "./soc2.png";
import gdpr from "./gdpr.png";
import ccpa from "./ccpa.jpg";
import email from "./email.png";
import edits from "../../images/edits.png";
import coworkers from "./coworkers.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class LandingPageFinance extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  handleTermsClick = () => {
    const { history } = this.props;
    history.push("/terms");
  }
  handlePrivacyClick = () => {
    const { history } = this.props;
    history.push("/privacy");
  }
  handleTryFreeClick = () => {
    const { history } = this.props;
    history.push("/login");
  };

  handleDemoClick = () => {
    const { history } = this.props;
    history.push("/contact");
  };

  handlePricingClick = () => {
    const { history } = this.props;
    history.push("/pricing");
  };

  render() {
    return (
      <div class="">
        {/* Hero section */}
        <div className="">
          <LandingPageHeader showFreeTrial={true} />
          <div 
              class="section1 px-2 sm:px-6 md:px-0 xl:px-4 pt-20 pb-6 sm:pt-32 text-deep-indigo lg:flex sm:max-w-[1480px] sm:mx-auto  justify-center"
            >
            <div className="flex flex-col gap-y-6 lg:gap-y-12 lg:w-1/2 sm:px-20">
              <div class="flex flex-col mb-4 text-center lg:text-left px-0.5 lg:px-0 text-black text-5xl lg:text-6xl font-manrope">
                    AI meeting assistant for
                  {/* <span class="font-manrope"> */}
                   <span class="text-indigo-500">financial advisors</span>
                  {/* </span> */}
                </div>
            

              <p className="md:text-lg text-center lg:text-left px-8 sm:px-0 leading-6 text-black/80">
                 Focus fully on your clients during online meetings while Xylo takes notes. Xylo will capture every detail with 99% accuracy and extract financial details into structured notes for you.
              </p>
              

              <div className="flex flex-col sm:flex-row sm:space-x-8 justify-center items-center lg:justify-start sm:items-start">
                <div class="flex flex-col">
                    <button
                      className="bg-indigo-500 hover:opacity-70 text-white px-6 py-3 rounded-md font-semibold sm:mb-0"
                      onClick={this.handleTryFreeClick}
                    >
                      Get started - it's free
                    </button>
                    <div class="text-black/60 text-sm pt-2 mb-8 sm:mb-0 text-center sm:text-left">No credit card required</div>
                </div>
                 <button
                  className="bg-white hover:opacity-70 text-black border border-black px-6 py-3 rounded-md font-semibold"
                  onClick={this.handleDemoClick}
                >
                  Book a demo
                </button>
              </div>
              {/* <div>
                <div class="flex justify-center items-center gap-x-12 lg:justify-start pt-4 sm:pt-8 sm:pb-8 lg:pt-0">
                  <div class="">
                        <span><img class="inline h-10 w-10 bg-transparent  mx-1 mr-2" src={Google}></img></span>
                        <span><img class="inline h-10 w-10 sm:mx-2  mx-1 bg-white" src={Teams}></img></span>
                        <div class="text-sm text-center pt-4">Works with</div>
                    </div>
                    <div class="flex gap-x-4">
                      <div class="text-sm text-center"><img src={soc2} class ="h-12 w-12 mb-2"/>SOC2</div>
                      <div class="text-sm text-center"><img src={gdpr} class ="h-12 w-12 mb-2"/>GDPR</div>
                      <div class="text-sm text-center"><img src={ccpa} class ="h-12 w-12 mb-2"/>CCPA</div>
                    </div>
                </div>
              </div> */}
              </div> 
              
            <div class="hidden lg:flex lg:flex-col pt-12 sm:pt-0 sm:w-1/2 px-8">
              <div class="rounded-xl flex items-center justify-center max-w-lg">
                <div class="bg-white rounded-xl overflow-hidden">
                  <LazyLoadImage
                    src={coworkers} 
                  />
                </div>       
              </div>
              <div class="flex items-center justify-start">
                <div class="flex justify-center items-center gap-x-12 pt-4 sm:pt-8 sm:pb-8 lg:pt-8">
                  <div class="">
                        <span><img class="inline h-10 w-10 bg-transparent  mx-1 mr-2" src={Google}></img></span>
                        <span><img class="inline h-10 w-10 sm:mx-2  mx-1 bg-white" src={Teams}></img></span>
                        <span><img class="inline h-10 w-10 sm:mx-2  mx-1 bg-white" src={Zoom}></img></span>
                        <div class="text-sm text-center pt-4">Works with</div>
                    </div>
                    <div class="flex gap-x-4">
                      <div class="text-sm text-center"><img src={soc2} class ="h-12 w-12 mb-2"/>SOC2</div>
                      <div class="text-sm text-center"><img src={gdpr} class ="h-12 w-12 mb-2"/>GDPR</div>
                      <div class="text-sm text-center"><img src={ccpa} class ="h-12 w-12 mb-2"/>CCPA</div>
                    </div>
                </div>
              </div>
            </div>
            <div class="lg:hidden flex flex-col pt-6 lg:w-1/2 self-center rounded-lg px-4 flex items-center justify-center">
            <div class="rounded-xl flex items-center justify-center max-w-lg">
               <div class="bg-white rounded-xl overflow-hidden">
                <LazyLoadImage
                  src={coworkers} 
                />
              </div>
               
            </div>
            <div class="flex items-center justify-start">
                <div class="flex justify-center items-center gap-x-12 pt-4 sm:pt-8 sm:pb-8 lg:pt-8">
                  <div class="">
                        <span><img class="inline h-10 w-10 bg-transparent  mx-1 mr-2" src={Google}></img></span>
                        <span><img class="inline h-10 w-10 sm:mx-2  mx-1 bg-white" src={Teams}></img></span>
                        <span><img class="inline h-10 w-10 sm:mx-2  mx-1 bg-white" src={Zoom}></img></span>
                        <div class="text-sm text-center pt-4">Works with</div>
                    </div>
                    <div class="flex gap-x-4">
                      <div class="text-sm text-center"><img src={soc2} class ="h-12 w-12 mb-2"/>SOC2</div>
                      <div class="text-sm text-center"><img src={gdpr} class ="h-12 w-12 mb-2"/>GDPR</div>
                      <div class="text-sm text-center"><img src={ccpa} class ="h-12 w-12 mb-2"/>CCPA</div>
                    </div>
                </div>
              </div>
             
            </div>

            {/* <div>
                <div class="flex justify-center items-center gap-x-12 lg:justify-start pt-4 sm:pt-8 sm:pb-8 lg:pt-0">
                  <div class="">
                        <span><img class="inline h-10 w-10 bg-transparent  mx-1 mr-2" src={Google}></img></span>
                        <span><img class="inline h-10 w-10 sm:mx-2  mx-1 bg-white" src={Teams}></img></span>
                        <div class="text-sm text-center pt-4">Works with</div>
                    </div>
                    <div class="flex gap-x-4">
                      <div class="text-sm text-center"><img src={soc2} class ="h-12 w-12 mb-2"/>SOC2</div>
                      <div class="text-sm text-center"><img src={gdpr} class ="h-12 w-12 mb-2"/>GDPR</div>
                      <div class="text-sm text-center"><img src={ccpa} class ="h-12 w-12 mb-2"/>CCPA</div>
                    </div>
                </div>
              </div> */}
          </div>
         
        </div>

          {/* DIG DEEPER */}
          <div className="section4 flex flex-col sm:flex-row sm:max-w-[1380px] sm:mx-auto justify-between py-8 px-18">
            <div class="sm:w-1/2 px-8 sm:px-16 self-center pb-8 sm:pb-0 text-base sm:text-lg order-1">
                <div>
                    <div class="text-4xl  font-black pb-8 sm:pb-12 font-manrope self-center ">Capture key <span class="text-indigo-500">financial and personal details</span> from meetings</div>
                    <div class="pb-4 flex flex-row text-base"><CheckIcon class=" flex-shrink-0 inline mr-4 text-black/70 h-6 w-6" />Xylo automatically extracts specific financial & personal details about your client.</div>
                    <div class="pb-4 flex flex-row text-base"><CheckIcon class=" flex-shrink-0 inline mr-4 text-black/70 h-6 w-6" />Choose from a range of meeting analysis templates such as intro/prospect, discovery, annual reviews, tax planning and many more.</div>
                    <div class="pb-4 flex flex-row text-base"><CheckIcon class=" flex-shrink-0 inline mr-4 text-black/70 h-6 w-6" />Notes are organised, categorised and editable.</div>    
                    </div>
            </div>
            <div class="sm:w-3/5 px-2 order-2 pb-8 sm:pb-0"> 
            <div class="p-2 sm:p-8 bg-gradient-to-br from-indigo-50 to-indigo-100/50 rounded-xl flex items-center justify-center max-w-xl">
              <div>
                <LazyLoadImage
                  src={IntroCall} 
               />
              </div>
            </div>
            </div>
        </div>

        {/* Dashboard */}
        {/* <div class="py-12 px-8 max-w-2xl">
            <img class="rounded-lg border shadow-lg" src={IntroCall} ></img>
        </div> */}

                   {/* Getting distracted */}
          {/* <div className="sm:max-w-[1380px] sm:mx-auto justify-between sm:py-12 px-18" >
            <div class="rounded-lg flex items-center justify-center">
              <div class="py-12 sm:py-0 flex flex-col gap-y-4 font-manrope  justify-center px-6 sm:px-0">
                <div class="text-4xl text-center text-indigo-600 pb-12">Getting distracted taking notes during client meetings?</div>
                <div class="flex justify-center items-center text-base sm:text-lg">
                    <div class="flex flex-col gap-y-8 sm:gap-y-4 px-4 sm:px-0">
                      <div class="flex flex-row justify-center"><CheckIcon class="flex-shrink-0 inline mr-2 h-6 w-6 text-black/70" />Focus fully on your clients and actively listen during meetings</div>
                      <div class="flex flex-row justify-center"><CheckIcon class="flex-shrink-0 inline mr-2 h-6 w-6 text-black/70" />Stay compliant by capturing every detail from meetings</div>
                      <div class="flex flex-row justify-center"><CheckIcon class="flex-shrink-0 inline mr-2 h-6 w-6 text-black/70" />Spend more time strengthening engagement and relationships with existing clients</div>
                    </div>
                </div>
              </div>
            </div>
          </div> */}

        {/* How to use */}
        {/* <div class="section2 sm:max-w-[1380px] sm:mx-auto"      
          >
          <div className="px-6 pb-12 sm:pb-8 sm:pt-0 rounded-xl  text-black">
            <div className="flex flex-col sm:flex-row gap-y-6 sm:gap-y-12 gap-x-12 justify-center w-full">
               
                <div class="sm:w-1/3 px-6">
                  <div class="flex sm:flex-col justify-center items-center">
                    <div class="flex justify-center py-2 mr-4"> 
                              <img
                              src={Transcription}
                              alt="mic"
                              class="rounded-md bg-white h-8 w-8 sm:h-12 sm:w-12"
                              />
                      </div>
                    <div className="text-2xl  font-black mb-6 font-manrope pt-6 text-center font-light">
                      Run Meetings
                    </div>
                  </div>
                   
                  <div class="text-center text-sm sm:text-base">
                    <span class="">
                        {`Invite your Xylo assistant to join your online meetings`}
                    </span>
                  </div>
                </div>

                <div class="hidden sm:flex items-center justify-center">{<img src={arrowRight} class="h-10 w-10" />}</div>

          
                <div class="sm:w-1/3 px-6">
                  <div class="flex sm:flex-col justify-center items-center">
                      <div class="flex justify-center py-2 mr-4"> 
                          <img
                          src={Microphone}
                          alt="mic"
                          class="rounded-md bg-white h-8 w-8 sm:h-12 sm:w-12"
                          />
                    </div>
                    <h1 className="text-2xl  font-black mb-6 font-manrope pt-6 text-center font-light">
                      Xylo generates notes
                    </h1>
                  </div>

                  <div class="flex justify-center items-center text-sm sm:text-base">
                    <div class="flex flex-col">
                      <div><CheckIcon class="inline mr-2 h-4 w-4" />Accurate, editable transcripts</div>
                      <div><CheckIcon class="inline mr-2 h-4 w-4" />AI summaries</div>
                      <div><CheckIcon class="inline mr-2 h-4 w-4" />Key financial & personal details</div>
                      <div><CheckIcon class="inline mr-2 h-4 w-4" />Follow-up tasks</div>
                    </div>
                  </div>
                </div>

                <div class="hidden sm:flex items-center justify-center">{<img src={arrowRight} class="h-10 w-10" />}</div>
                  
                
                 <div class="sm:w-1/3 px-6">
                 <div class="flex sm:flex-col justify-center items-center ">
                      <div class="flex justify-center py-2 mr-4"> 
                              <img
                              src={Discover}
                              alt="mic"
                              class="rounded-md bg-white h-8 w-8 sm:h-12 sm:w-12"
                              />
                      </div>
                    <h1 className="text-2xl  font-black mb-6 font-manrope pt-6 text-center font-light">
                      Export insights
                    </h1>
                  </div>
                  <div class="text-center text-sm sm:text-base">
                    <span class="">Seamlessly export meeting notes and follow-up tasks into your CRM</span>
                  </div>
            
                </div>
              </div>
                
          </div>
        </div> */}

        {/* Accuracy */}
        <div className="section4 flex flex-col sm:flex-row sm:max-w-[1380px] sm:mx-auto justify-between py-4 sm:py-20 px-18">
            <div class="sm:w-1/2 px-8 sm:px-20 self-center pb-8 sm:pb-0 text-base sm:text-lg order-1 sm:order-2">
                <div>
                    <div class="text-4xl  font-black pb-8 sm:pb-12 font-manrope self-center"><span class="text-indigo-500">99%</span> accurate transcripts and analysis</div>
                      <div class="text-base">Give Xylo a list of custom words and phrases and we will make sure these terms are captured accurately, making your notes more precise and meaningful. Make quick edits to your transcripts for further precision and allow Xylo to analyse these edited transcripts for you.</div>
                    </div>
            </div>
            <div class="sm:w-3/5 px-2 sm:px-8 mx-auto order-2 sm:order-1 pb-8 sm:pb-0"> 
            <div class="p-2 sm:p-8 bg-gradient-to-br from-indigo-50 to-indigo-100/50 rounded-xl flex items-center justify-center max-w-xl">
               <div class="bg-white rounded-xl overflow-hidden">
                <LazyLoadImage
                  src={edits} 
                />
              </div>
               
            </div>
            </div>
        </div>

          {/* Followup */}

         <div className="section4 flex flex-col sm:flex-row sm:max-w-[1380px] sm:mx-auto justify-between py-4 sm:py-20 px-18">
            <div class="sm:w-1/2 px-8 sm:px-20 self-center pb-8 sm:pb-0 text-base sm:text-lg order-1">
                <div>
                    <div class="text-4xl  font-black pb-8 sm:pb-12 font-manrope self-center">Follow up with clients in <span class="text-indigo-500">seconds</span></div>
                      <div class="text-base">Xylo takes care of follow-ups so you can focus on your clients' needs and grow your business. Xylo automates follow-ups by generating a list of next steps and draft emails you can send to clients immediately after meetings.</div>
                    </div>
            </div>
            <div class="sm:w-3/5 px-2 sm:px-8 mx-auto order-2 pb-8 sm:pb-0"> 
            <div class="p-2 sm:p-8 bg-gradient-to-br from-indigo-50 to-indigo-100/50 rounded-xl flex items-center justify-center max-w-lg">
               <div class="bg-white rounded-xl overflow-hidden p-4">
                <LazyLoadImage
                  src={email} 
                />
              </div>
               
            </div>
            </div>
        </div>


        <div class="sm:max-w-[1380px] sm:mx-auto py-4 sm:py-20 px-18 bg-white">
          <div class="flex flex-col items-center justify-center gap-y-12 sm:gap-y-16">
            <div class="flex flex-col gap-y-12 sm:gap-y-0 sm:flex-row gap-x-16">
              <div class="w-80 sm:w-96 border border-indigo-900 border-opacity-70 p-6 sm:p-8 rounded-lg flex flex-col justify-between">
                <div class="flex pb-8 items-center">
                  <ArrowTrendingDownIcon class="flex-shrink-0 mr-4 h-8 w-8 text-indigo-600" />
                  <div class="font-manrope text-xl sm:text-2xl font-bold">Cut meeting admin & costs by 90% </div>
                </div>
                <div class="text-sm sm:text-base">Xylo reduces time-consuming tasks by automating note-taking and generating follow-ups, saving advisors 10+ hours per week.</div>
              </div>
              <div class="w-80 sm:w-96 border  border-indigo-900 border-opacity-70 p-6 sm:p-8 rounded-lg flex flex-col justify-between">
                 <div class="flex pb-8 items-center">
                    <UserPlusIcon class="mr-4 flex-shrink-0 h-8 w-8 text-indigo-600" />
                     <div class="font-manrope text-xl sm:text-2xl font-bold">Improve client service</div>
                  </div>
                <div class="text-sm sm:text-base">Spend more time meeting prospects and focusing on servicing existing clients better, resulting in more clients and happier clients.</div>
              </div>
            </div>
            <div class="flex flex-col gap-y-12 sm:gap-y-0 sm:flex-row gap-x-16">
            <div class="w-80 sm:w-96 border  border-indigo-900 border-opacity-70 p-6 sm:p-8 rounded-lg flex flex-col justify-between">
                <div class="flex pb-8 items-center">
                  <ShieldCheckIcon class="mr-4 flex-shrink-0 h-8 w-8 text-indigo-600" />
                  <div class="font-manrope text-xl sm:text-2xl font-bold">Compliance first</div>
                </div>
                <div class="text-sm sm:text-base">Retain comprehensive transcripts of client meetings - providing a clear audit trail, helping maintain compliance.</div>
              </div>
              <div class="w-80 sm:w-96 border  border-indigo-900 border-opacity-70 p-6 sm:p-8 rounded-lg flex flex-col justify-between">
              <div class="flex pb-8 items-center">
                  <ArrowUpTrayIcon class="mr-4 flex-shrink-0 h-8 w-8 text-indigo-600" /> 
                  <div class="font-manrope text-xl sm:text-2xl font-bold">Export data seamlessly</div>
                </div>
                <div class="text-sm sm:text-base">Fully editable AI notes that you can save directly to your CRM.</div>
              </div>
            </div>
          </div>
        </div>

     {/* More time */}

        <div className="sm:max-w-[1380px] sm:mx-auto justify-between py-20 px-18" >
          <div class="rounded-lg flex items-center justify-center">
            <div class="flex flex-col gap-y-4 font-manrope  justify-center px-8 sm:px-0">
              <div class="text-4xl text-center">Spend more time <span class="text-indigo-500">servicing clients</span>, and less time creating notes</div>
              <div class="flex flex-col sm:flex-row gap-x-4 gap-y-8 items-center justify-center pt-8">
              <div class="">
                    <button
                      className="bg-indigo-500 hover:opacity-70 text-white px-6 py-3 rounded-md font-semibold sm:mb-0"
                      onClick={this.handleTryFreeClick}
                    >
                      Get started - it's free
                    </button>
                </div>
                 <button
                  className="bg-white hover:opacity-70 text-black border border-black px-6 py-3 rounded-md font-semibold"
                  onClick={this.handleDemoClick}
                >
                  Book a demo
                </button>
              </div>
              </div>
             
          </div>
        </div>
     
       

        {/* FOOTER */}
        <div className="bg-neutral-200 bg-opacity-40">
          <div class="px-6 sm:px-12 py-16">
            <div class="flex items-center justify-between w-full">
              <div class="flex items-center justify-center flex-shrink-0 ">
                <img src={LogoName} class="h-16 w-16" alt="LogoName" />
                <img src={LogoImage} class="h-6 w-6" alt="LogoImage" />
              </div>
              <span></span>
             <div class="text-sm">
             
                <span
                  class="cursor-pointer px-2 sm:px-6 hover:opacity-60"
                  onClick={this.handleDemoClick}
                >
                  Contact Us
                </span>
                <span
                  class="flex cursor-pointer px-2 sm:px-6 hover:opacity-60 flex-shrink-0 pt-4"
                  onClick={this.handleTermsClick}
                >
                  Terms And Conditions
                </span>
                <span
                  class="flex cursor-pointer px-2 sm:px-6 hover:opacity-60 flex-shrink-0 pt-4"
                  onClick={this.handlePrivacyClick}
                >
                  Privacy Policy
                </span>
                
              </div> 

              
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(LandingPageFinance);
